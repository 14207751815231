import React from 'react'

export default function Footer() {
  return (
    <footer>
      <div>
        <p>Built by Ruth &copy; {(new Date().getFullYear())}</p>
      </div>
    </footer>
  )
}